import React from "react";
import "../styles/AboutUs.scss";

import services from "../assets/images/bitcoin-crypto-currency-diagram.png";
import currencies from "../assets/images/dolar-versus-euro-note-finance.png";
import goldCoins from "../assets/images/russian-gold-coins.png";

import leftD from "../assets/images/right-design.png";
import rightD from "../assets/images/left-design.png";
import miniLeftD from "../assets/images/mini-left-design.png";
import miniRightD from "../assets/images/mini-right-design.png";

const AboutUsPage = () => {
  return (
    <div className="about-us-container">
      {/* Main Heading */}
      <div className="main-heading">
        <img src={miniLeftD} className="hidden max-[600px]:block" />
        <img src={rightD} className="max-[600px]:hidden" />
        <h1>About Us</h1>
        <img src={miniRightD} className="hidden max-[600px]:block" />
        <img src={leftD} className="max-[600px]:hidden" />
      </div>
      <div className="content-container">
        {/* Company Overview Section */}
        <div className="gradient-border5">
          <section className="company-overview h-[210px]">
            <div className="text-content">
              <div className="bg-white p-[0.75rem] left-[-1rem] top-[-2.5rem] w-fit relative max-[500px]:top-[-2rem] max-[1600px]:left-[-1rem] max-[1600px]:top-[-3rem] max-[1530px]:top-[-2.5rem] max-[1300px]:top-[-2.5rem]">
                <h2 className="">Company Overview</h2>
              </div>
              <p className=" mr-[4rem] mb-[2rem] ml-[2rem] mt-[-2rem] text-[1.125rem]">
                The company Mehr operates under an official license granted by
                the Turkish Chamber of Commerce, ensuring compliance with all
                regulatory and legal standards required for business activities
                in Turkey. Duly registered and recognized, Mehr adheres to
                procedures and protocols established by the chamber,
                demonstrating transparency and accountability. This registration
                validates Mehr's credibility in the market and assures partners
                and clients of its lawful status. By operating under this
                official license, Mehr upholds high standards of integrity,
                professionalism, and compliance, strengthening trust among
                stakeholders both within and beyond Turkey. Mehr danışmanlık ve
                SARRAFIYE ticaret limited şirketi
              </p>
            </div>
            <img src={goldCoins} alt="Gold Coins" />
          </section>
        </div>
        {/* 
        <div className="gradient-border5">
      
          <section className="mission-values h-[405]">
            <img src={currencies} alt="Currencies" />
            <div className="text-content">
              <div className="bg-white p-[0.75rem] left-[38rem] top-[-2rem] w-fit relative max-[400px]:left-[3.5rem] max-[500px]:left-[8rem] max-[500px]:top-[-2rem] max-[1600px]:left-[40rem] max-[1600px]:top-[-4rem] max-[1530px]:top-[-3rem] max-[1530px]:left-[37rem] max-[1300px]:left-[29rem] max-[1300px]:top-[-2rem]">
                <h2 className="">Our Mission and Values</h2>
              </div>
              <p className=" mr-[2rem] mb-[2rem] ml-[4rem] text-[1.125rem]">
                Our services include consulting in matters of buying, selling,
                exporting, importing, and producing molten gold, bullion, and
                precious stones. The Mr. Dolar website is designed to provide
                information on gold prices and the rates of physical currencies
                and cryptocurrencies worldwide.
              </p>
            </div>
          </section>
        </div>

         */}
        <div className="gradient-border5">
          {/* Our Services Section (Updated) */}
          <section className="our-services h-[320px]">
            <img src={services} alt="Our Services" className="max-[500px]:hidden" />

            <div className="text-content">
              <div className="bg-white p-[0.75rem] left-[80%] top-[-2.5rem] w-fit relative max-[500px]:top-[-2rem] max-[500px]:left-[66%] max-[1600px]:left-[83%] max-[1600px]:top-[-3rem] max-[1300px]:top-[0.4rem] max-[1300px]:left-[78%]">
                <h2 className="">Our Services</h2>
              </div>
              <div className="service-con  mr-[4rem] mb-[2rem] ml-[2rem] mt-[-2rem] flex flex-col gap-[2rem] max-[1300px]:mt-[0rem]">
                <div>
                  Our services include consulting in matters of buying, selling,
                  exporting, importing, and producing molten gold, bullion, and
                  precious stones. The Mr. Dolar website is designed to provide
                  information on gold prices and the rates of physical
                  currencies and cryptocurrencies worldwide.
                </div>
                <ul>
                  <li>
                    <div className="flex mr-1 max-[500px]:items-center">
                      <div className="gradient-num-border">
                        <div className="gradient-num">1</div>
                      </div>
                      <strong className="whitespace-nowrap">
                        Real-Time Rates:{" "}
                      </strong>
                    </div>
                    <div>
                      Access to up-to-date and real-time market rates for gold,
                      currencies, and cryptocurrencies.
                    </div>
                  </li>
                  <li>
                    <div className="flex mr-1 max-[500px]:items-center">
                      <div className="gradient-num-border">
                        <div className="gradient-num">2</div>
                      </div>
                      <strong className="whitespace-nowrap">
                        Conversion Tools:{" "}
                      </strong>
                    </div>
                    <div>
                      Comprehensive converters for converting various
                      currencies, gold, and cryptocurrencies.
                    </div>
                  </li>
                  <li>
                    <div className="flex mr-1 max-[500px]:items-center">
                      <div className="gradient-num-border">
                        <div className="gradient-num">3</div>
                      </div>
                      <strong className="whitespace-nowrap">
                        Market Analysis:{" "}
                      </strong>
                    </div>
                    <div>
                      Providing detailed analyses and reports to assist you in
                      making informed investment decisions.
                    </div>
                  </li>
                </ul>
              </div>
            </div>

            <img src={services} alt="Our Services" className="hidden max-[500px]:block" />

          </section>
        </div>
      </div>
    </div>
  );
};

export default AboutUsPage;
