import React from "react";
import "./Footer.scss";

import telegram from "../../assets/icons/telegram.svg";
import instagram from "../../assets/icons/instagram.svg";
import whatsapp from "../../assets/icons/whatsappp.svg";
import facebook from "../../assets/icons/facebook.svg";

const Footer = () => {
  return (
    <footer className="footer">
      <hr />
      <div className="subtitle">
        <p className="p">© 2024 MrDolar all rights served.</p>
        <div className="social">
          <a href="https://wa.me/+905445562283">
            <img src={whatsapp} />
          </a>
          {/* <a>
            <img src={facebook} />
          </a> */}
          <a>
            <img src={telegram} />
          </a>
          <a href="https://instagram.com/mrdolarfarsi">
            <img src={instagram} />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
