import "./worldClocks.scss";
import { useEffect, useState } from "react";
import moment from "moment-timezone";
import Clocks from "../Clocks";
import Clock from "react-clock";
import "react-clock/dist/Clock.css";
import { useSelector } from "react-redux";

const WorldClocks = () => {
  function convertTZ(date, tzString) {
    return new Date(
      (typeof date === "string" ? new Date(date) : date).toLocaleString(
        "en-US",
        { timeZone: tzString }
      )
    );
  }

  const [value, setValue] = useState(convertTZ(new Date(), "Asia/Tehran"));
  const [value2, setValue2] = useState(convertTZ(new Date(), "Asia/Istanbul"));
  const [value3, setValue3] = useState(convertTZ(new Date(), "Europe/London"));
  const [value4, setValue4] = useState(convertTZ(new Date(), "Asia/Shanghai"));
  const [value5, setValue5] = useState(convertTZ(new Date(), "Asia/Dubai"));

  const isToggledFromStore = useSelector((state) => state.isToggled);

  useEffect(() => {
    const interval1 = setInterval(function () {
      setValue(convertTZ(new Date(), "Asia/Tehran"));
    }, 1000);
    const interval2 = setInterval(function () {
      setValue2(convertTZ(new Date(), "Asia/Istanbul"));
    }, 1000);

    const interval3 = setInterval(function () {
      setValue3(convertTZ(new Date(), "Europe/London"));
    }, 1000);
    const interval4 = setInterval(function () {
      setValue4(convertTZ(new Date(), "Asia/Shanghai"));
    }, 1000);
    const interval5 = setInterval(function () {
      setValue5(convertTZ(new Date(), "Asia/Dubai"));
    }, 1000);

    return () => {
      clearInterval(interval1);
      clearInterval(interval2);
      clearInterval(interval3);
      clearInterval(interval4);
      clearInterval(interval5);
    };
  }, []);

  const getFormattedTime = (value) => {
    const hours = String(value.getHours()).padStart(2, "0");
    const minutes = String(value.getMinutes()).padStart(2, "0");
    const seconds = String(value.getSeconds()).padStart(2, "0");
    return `${hours}:${minutes}:${seconds}`;
  };

  return (
    <>
      <section className="clock-container">
        <div className="trapezoid-parent">
          <div className="trapezoid-child clocks">
            <div className="analog-clock">
              <div className="content">
                <h5 className="CountryTitle">Tehran</h5>
                <div className={"digital-clock"}>{getFormattedTime(value)}</div>
              </div>
              <div className="gradient-border7 clock-wrapper">
                <Clock
                  className={`${
                    isToggledFromStore === false ? "white-clock" : ""
                  }`}
                  value={value}
                />
                <div
                  className={`center-circle ${
                    isToggledFromStore === false ? "white-circle" : ""
                  }`}
                ></div>
              </div>
            </div>
            <div className="analog-clock">
              <div className="content">
                <h5 className="CountryTitle">Istanbul</h5>
                <div className={"digital-clock"}>
                  {getFormattedTime(value2)}
                </div>
              </div>
              <div className="gradient-border7 clock-wrapper">
                <Clock
                  className={`${
                    isToggledFromStore === false ? "white-clock" : ""
                  }`}
                  value={value2}
                />
                <div
                  className={`center-circle ${
                    isToggledFromStore === false ? "white-circle" : ""
                  }`}
                ></div>
              </div>
            </div>
            <div className="analog-clock">
              <div className="content">
                <h5 className="CountryTitle">London</h5>
                <div className={"digital-clock"}>
                  {getFormattedTime(value3)}
                </div>
              </div>
              <div className="gradient-border7 clock-wrapper">
                <Clock
                  className={`${
                    isToggledFromStore === false ? "white-clock" : ""
                  }`}
                  value={value3}
                />
                <div
                  className={`center-circle ${
                    isToggledFromStore === false ? "white-circle" : ""
                  }`}
                ></div>
              </div>
            </div>
            <div className="analog-clock">
              <div className="content">
                <h5 className="CountryTitle">Shanghai</h5>
                <div className={"digital-clock"}>
                  {getFormattedTime(value4)}
                </div>
              </div>
              <div className="gradient-border7 clock-wrapper">
                <Clock
                  className={`${
                    isToggledFromStore === false ? "white-clock" : ""
                  }`}
                  value={value4}
                />
                <div
                  className={`center-circle ${
                    isToggledFromStore === false ? "white-circle" : ""
                  }`}
                ></div>
              </div>
            </div>
            <div className="analog-clock">
              <div className="content">
                <h5 className="CountryTitle">Dubai</h5>
                <div className={"digital-clock"}>
                  {getFormattedTime(value5)}
                </div>
              </div>
              <div className="gradient-border7 clock-wrapper">
                <Clock
                  className={`${
                    isToggledFromStore === false ? "white-clock" : ""
                  }`}
                  value={value5}
                />
                <div
                  className={`center-circle ${
                    isToggledFromStore === false ? "white-circle" : ""
                  }`}
                ></div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default WorldClocks;
