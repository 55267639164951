import * as React from "react";
import "./clock.scss";

export default class Clocks extends React.Component {
  clockInterval = "";
  constructor(props) {
    super(props);
    this.handleDate = this.handleDate.bind(this);
    this.state = {
      hours: "",
      minutes: "",
      seconds: "",
    };
  }

  componentDidMount() {
    this.clockInterval = setInterval(this.handleDate, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.clockInterval);
  }

  render() {
    const { hours, minutes, seconds } = this.state;
    const secondsStyle = {
      transform: `rotate(${seconds * 6}deg)`,
    };
    const minutesStyle = {
      transform: `rotate(${minutes * 6}deg)`,
    };
    const hoursStyle = {
      transform: `rotate(${hours * 30}deg)`,
    };
    const { title } = this.props;
    return (
      <div className={"clock"}>
        <div className="gradient-border7">
          <div className="analog-clock">
            <div className="gradient-border8">
              <div className="ClockCircle"></div>
            </div>
            <div className="dial white-border1" style={minutesStyle}>
              <div className="dial minutes" />
            </div>
            <div className="dial white-border2" style={hoursStyle}>
              <div className="dial hours" />
            </div>
          </div>
        </div>
        <div className="content">
          <h5 className="CountryTitle">{title}</h5>

          <div className={"digital-clock"}>
            {hours}:{minutes}:{seconds}
          </div>
        </div>
      </div>
    );
  }

  handleDate() {
    const { datediff } = this.props;
    const { minutesdiff } = this.props;
    const date = new Date();
    date.setHours(date.getHours() + datediff);
    date.setMinutes(date.getMinutes() + minutesdiff);
    let hours = this.formatTime(date.getHours());
    let minutes = this.formatTime(date.getMinutes());
    let seconds = this.formatTime(date.getSeconds());
    this.setState({ hours, minutes, seconds });
  }

  formatTime(time) {
    return time < 10 ? `0${time}` : time;
  }
}
