import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import "./Header.scss";
import ToggleSwitch from "../ToggleSwitch";
import logo from "../../assets/images/logo.png";
import darkLogo from "../../assets/images/dark-logo.png";
import activeIcon from "../../assets/icons/indicator.svg"; // Your active icon here
import Sidebar from "../Sidebar";
import { useSelector } from "react-redux";

const Header = () => {
  // states
  const [showSidebar, setShowSidebar] = useState(false);

  const isToggledFromStore = useSelector((state) => state.isToggled);

  const location = useLocation();

  const isActive = (path) => {
    return location.pathname === path;
  };

  return (
    <div className="header">
      <div className="header-2">
        <img src={isToggledFromStore ? logo : darkLogo} alt="Mr.Dollar" />
        <div className="menu">
          <div className="label-indicator">
            <a href="/" className="title-14">
              {isActive("/") && (
                <img
                  className="inline mr-[0.5rem]"
                  src={activeIcon}
                  alt="Active"
                />
              )}
              Home
            </a>
          </div>
          <div className="label-indicator">
            <a href="/exchange-rate" className="title-14">
              {isActive("/exchange-rate") && (
                <img
                  className="inline mr-[0.5rem]"
                  src={activeIcon}
                  alt="Active"
                />
              )}
              Exchange rate
            </a>
          </div>
          <div className="label-indicator">
            <a href="/about-us" className="title-14">
              {isActive("/about-us") && (
                <img
                  className="inline mr-[0.5rem]"
                  src={activeIcon}
                  alt="Active"
                />
              )}
              About us
            </a>
          </div>
          <div className="label-indicator">
            <a href="/contact-us" className="title-14">
              {isActive("/contact-us") && (
                <img
                  className="inline mr-[0.5rem]"
                  src={activeIcon}
                  alt="Active"
                />
              )}
              Contact us
            </a>
          </div>
        </div>
        <ToggleSwitch />
        <Sidebar showSidebar={showSidebar} setShowSidebar={setShowSidebar} />
      </div>
      <hr />
    </div>
  );
};

export default Header;
