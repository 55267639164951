import React, { useState, useEffect } from "react";
import { ReactComponent as SunIcon } from "../../assets/icons/wb_sunny.svg"; // Replace with the actual path to your sun icon
import { ReactComponent as MoonIcon } from "../../assets/icons/bedtime.svg"; // Replace with the actual path to your moon icon
import { ReactComponent as SunIconLight } from "../../assets/icons/wb_sunny-light.svg"; // Replace with the actual path to your sun icon
import { ReactComponent as MoonIconDark } from "../../assets/icons/bedtime-dark.svg"; // Replace with the actual path to your moon icon
import "./ToggleSwitch.scss"; // Import the SCSS file

import { useDispatch, useSelector } from "react-redux";
import { setIsToggledAction } from "../../store/action";

const ToggleSwitch = () => {
  const dispatch = useDispatch();

  // Retrieve the toggle state from Redux store
  const isToggledFromStore = useSelector((state) =>  
    // console.log(state)
  state.isToggled
  );

  const [isLight, setIsLight] = useState(isToggledFromStore);
  // console.log("isToggledFromStore:", isToggledFromStore);

  // Sync local state with Redux store whenever isToggledFromStore changes
  useEffect(() => {
    setIsLight(isToggledFromStore);
  }, [isToggledFromStore]);

  const handleToggle = () => {
    const newTheme = !isLight;
    setIsLight(newTheme);
    dispatch(setIsToggledAction(newTheme)); // Update Redux state
  };

  return (
    <div className="toggle-switch" onClick={handleToggle}>
      <div className={`toggle-knob ${isLight ? "light" : "dark"}`}>
        <div className="gradient-border6">
          {isLight ? <SunIcon /> : <MoonIconDark />}
        </div>
      </div>
      <div className="icon moon">
        {isLight ? <MoonIcon /> : <MoonIconDark />}
      </div>
      <div className="icon sun">{isLight ? <SunIcon /> : <SunIconLight />}</div>
    </div>
  );
};

export default ToggleSwitch;
